// Code generated by Slice Machine. DO NOT EDIT.

import Cards from "./Cards";
import Cta from "./Cta";
import Hero from "./Hero";
import Reviews from "./Reviews";
import Script from "./Script";
import Tarifs from "./Tarifs";
import Text from "./Text";
import TextWithImage from "./TextWithImage";

export { Cards, Cta, Hero, Reviews, Script, Tarifs, Text, TextWithImage };

export const components = {
    cards: Cards,
    cta: Cta,
    hero: Hero,
    reviews: Reviews,
    script: Script,
    tarifs: Tarifs,
    text: Text,
    text_with_image: TextWithImage,
};
